/* eslint-disable */
const mainMixins = {
    data() {
        return {

        }
    },
    methods: {
        async getCategories() {
            await axios.get(apiKS + 'api/store/categories?level=1', { headers: clientAPI })
            .then( (response) => {
                this.categories = response.data.data
            })
            .catch( error => {
                this.catchError(error)
            })
        },

        openLoading() {
            Swal.fire({
                title: this.$t('sweetalert.wait'),
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading()
                }
            })
        },

        catchError(error) {
			Swal.close()
	        if(error.response.status == 401)
	            router.replace('/login')
	        if(error.response.status == 500 || error.response.status == 404 || error.response.status == 405){
	            Swal.fire({
		        	icon: 'error',
		        	title: 'Error',
		        	text: error.response.statusText + ' ' + error.response.data.message,
		        })
	        }
		},

    }
}

export default mainMixins