import Vue from 'vue'
import App from './App.vue'
import store from './store'
import index from './router/index'
import VueI18n from 'vue-i18n'
import messages from './lang/messages'
import axios from 'axios'
import paype from './router/paype'
import mainMixins from './mixins/mainMixins'
import Swal from 'sweetalert2'

//window.$ = require('jquery')

//routes from every domain
Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.mixin(mainMixins)

window.axios = axios
window.Swal = Swal
//window.hostname = 'shoping.paype.com.mx'
window.hostname = location.hostname

const i18n = new VueI18n({
    locale: 'es', // set locale
    messages, // set locale messages
})


const router = () => {
    let routes
    if(window.hostname == 'localhost') {
        routes = index
        window.clientAPI = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + process.env.VUE_APP_API_KEY
        }
        window.apiKS = 'http://kreativisys.loc/'
    }
    if(window.hostname == 'shoping.paype.com.mx') {
        routes = paype
        window.clientAPI = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + process.env.VUE_APP_APIKEY_PAYPE
        }
        window.apiKS = 'https://erp.kreativisys.com.mx/'
    }

    return routes
}

window.router = router()

new Vue({
    router: router(),
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
