const messages = {
    en: {
        labels: {

        },
        buttons: {

        },
        links: {
            
        }
    },
    es: {
        labels: {
            header_customer_service: 'Servicio al cliente:',
            header_compare: 'Comparar',
            header_mxn: 'MXN $',
            header_eur: 'EUR €',
            header_usd: 'USD $',
            header_spanish: 'Español',
            header_english: 'English',
            header_my_account: 'Mi cuenta',
            header_checkout: 'Checkout',
            header_sign_in: 'Ingresar',
            option_all_categories: 'Todas las categorías',
            cart: 'Mi carrito',
        },
        placeholders: {
            header_search: 'Escriba para buscar ...'
        },
        buttons: {
            header_search_button: 'Buscar'
        },
        links: {
            all_categories: 'Categorías',
            home: 'Inicio',
            shop: 'Comprar',
            about_us: 'Nosotros',
            contact_us: 'Contacto',
            products: 'Productos',
        },
        sweetalert: {
            wait: ''
        }
    }
}

export default messages